import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import taskEnumerators from 'src/modules/task/taskEnumerators';
import dayjs from 'dayjs';

export default [
  {
    name: 'title',
    label: i18n('entities.task.fields.title'),
    schema: schemas.string(
      i18n('entities.task.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.task.fields.description'),
    schema: schemas.string(
      i18n('entities.task.fields.description'),
      {},
    ),
  },
  {
    name: 'targetCompletion',
    label: i18n('entities.task.fields.targetCompletion'),
    schema: schemas.date(
      i18n('entities.task.fields.targetCompletion'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'priority',
    label: i18n('entities.task.fields.priority'),
    schema: schemas.enumerator(
      i18n('entities.task.fields.priority'),
      {
        "required": true,
        "options": taskEnumerators.priority
      },
    ),
  },
  {
    name: 'status',
    label: i18n('entities.task.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.task.fields.status'),
      {
        "options": taskEnumerators.status
      },
    ),
  },
  {
    name: 'assignedTo',
    label: i18n('entities.task.fields.assignedTo'),
    schema: schemas.relationToOne(
      i18n('entities.task.fields.assignedTo'),
      {},
    ),
  },
  {
    name: 'tags',
    label: i18n('entities.task.fields.tags'),
    schema: schemas.relationToMany(
      i18n('entities.task.fields.tags'),
      {},
    ),
  },
  {
    name: 'interaction',
    label: i18n('entities.task.fields.interaction'),
    schema: schemas.relationToOne(
      i18n('entities.task.fields.interaction'),
      {},
    ),
  },
  {
    name: 'attachments',
    label: i18n('entities.task.fields.attachments'),
    schema: schemas.files(
      i18n('entities.task.fields.attachments'),
      {},
    ),
  },
];