import { AuthToken } from 'src/modules/auth/authToken';
import Axios from 'axios';
import config from 'src/config';
import { getLanguageCode } from 'src/i18n';
import Qs from 'qs';
import dayjs from 'dayjs';

const authAxios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer: {serialize: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (
          dayjs.isDayjs(value) ||
          value instanceof Date
        ) {
          return value.toISOString();
        }

        return value;
      },
    });
  }},
});

authAxios.interceptors.request.use(
  async function (options) {
    const token = AuthToken.get();

    if (token) {
      if (options.headers) {
        options.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    if (options.headers) {
      options.headers['Accept-Language'] = getLanguageCode();
    }

    return options;
  },
  function (error) {
    if (error.code !== 'ERR_CANCELED') {
      console.log('Request error: ', error);
    }
    return Promise.reject(error);
  },
);

authAxios.interceptors.request.use(
  async function (config) {
    if (config.url?.includes('undefined')) {
      throw new Axios.Cancel('Request canceled due to wrong url');
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default authAxios;
