import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/escalation/importer/escalationImporterSelectors';
import EscalationService from 'src/modules/escalation/escalationService';
import fields from 'src/modules/escalation/importer/escalationImporterFields';
import { i18n } from 'src/i18n';

const escalationImporterActions = importerActions(
  'ESCALATION_IMPORTER',
  selectors,
  EscalationService.import,
  fields,
  i18n('entities.escalation.importer.fileName'),
);

export default escalationImporterActions;