import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.watchlist.fields.name'),
    schema: schemas.string(
      i18n('entities.watchlist.fields.name'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.watchlist.fields.description'),
    schema: schemas.string(
      i18n('entities.watchlist.fields.description'),
      {},
    ),
  },
  {
    name: 'companies',
    label: i18n('entities.watchlist.fields.companies'),
    schema: schemas.relationToMany(
      i18n('entities.watchlist.fields.companies'),
      {
        "min": 1
      },
    ),
  },
];