import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class InteractionService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/interaction/${id}`,
      body,
    );

    return response.data;
  }

  static async addNote(id, data, callback) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/interaction/${id}/note`,
      body,
    );

    if (callback) {
      callback(response);
    }

    return response.data;
  }

  static async updateNote(id, noteId, data, callback) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/interaction/${id}/note/${noteId}`,
      body,
    );

    if (callback) {
      callback(response);
    }

    return response.data;
  }

  static async deleteNote(id, noteId, callback) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/interaction/${id}/note/${noteId}`,
    );

    if (callback) {
      callback(response);
    }

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/interaction`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/interaction`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/interaction/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/interaction/${id}`,
    );

    return response.data;
  }

  static async calendar(id, organiserEmail, time, duration) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/interaction/${id}/calendar?organiser=${organiserEmail}&time=${time}&duration=${duration}`,{
        responseType: 'text'
      }
    );

    return response.data;
  }

  static calendarURL(id, organiserEmail) {
    const tenantId = AuthCurrentTenant.get();

    const url = `/tenant/${tenantId}/interaction/${id}/calendar/${organiserEmail}`;

    return url;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/interaction`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/interaction/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findAnalyticsTeamSummary(filter) {
    const tenantId = AuthCurrentTenant.get();

    const params = {
      filter
    };

    const response = await authAxios.get(
      `/tenant/${tenantId}/interaction/analytics/teamSummary`,
      {params},
    );

    return response.data;
  }

  static async findByItemId(itemId: any) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/interaction/findByItemId/${itemId}`,
    );

    return response.data;
  }

}
