import { i18n } from 'src/i18n';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'companyName',
    label: i18n('entities.company.fields.companyName'),
    schema: schemas.string(
      i18n('entities.company.fields.companyName'),
      {
        "required": true,
        "min": 2,
        "max": 100
      },
    ),
  },
  {
    name: 'isin',
    label: i18n('entities.company.fields.isin'),
    schema: schemas.string(
      i18n('entities.company.fields.isin'),
      {
        "required": true,
        "min": 12,
        "max": 12
      },
    ),
  }
];