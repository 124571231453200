import { tenantSubdomain } from '../tenant/tenantSubdomain';

export default class AuthCurrentTenant {
  static selectAndSaveOnStorageFor(currentUser) {
    if (!currentUser) {
      return null;
    }

    if (!currentUser.tenants) {
      return null;
    }

    const activeTenants = currentUser.tenants.filter(
      (tenantUser) => tenantUser.status === 'active',
    );

    if (!activeTenants || !activeTenants.length) {
      return null;
    }

    // the below ensures that the user can switch workspace
    const tenant = (this.get() !== null) ? this.getTenantObject() : this.getDefaultActiveTenant(activeTenants);

    if (
      tenant &&
      tenantSubdomain.isEnabled &&
      !tenantSubdomain.isSubdomainOf(tenant.url)
    ) {
      return tenantSubdomain.redirectAuthenticatedTo(
        tenant.url,
      );
    }

    this.set(tenant);
    return tenant;
  }
  
  static getDefaultActiveTenant(activeTenants: any): any {
    const defaultTenant = activeTenants.filter((activeTenant) => activeTenant.isDefault);
    if (defaultTenant && defaultTenant.length === 1) 
      return defaultTenant[0].tenant;
    else
      return activeTenants[0].tenant;
  }

  static get() {
    const tenantObject = this.getTenantObject();

    if (tenantObject) {
      return tenantObject.id;
    }

    return null;
  }

  static getTenantObject() {
    const tenantASString =
      localStorage.getItem('tenant') || null;

    if (tenantASString) {
      return JSON.parse(tenantASString);
    }

    return null;
  }

  static getSettings() {
    const tenantASString =
      localStorage.getItem('tenant') || null;

    if (tenantASString) {
      const tenant = JSON.parse(tenantASString);

      if (tenant) {
        if (Array.isArray(tenant.settings)) {
          if (tenant.settings.length) {
            return tenant.settings[0];
          }
        } else {
          return tenant.settings;
        }
      }
    }

    return null;
  }

  static set(tenant) {
    if (!tenant) {
      return this.clear();
    }

    localStorage.setItem('tenant', JSON.stringify(tenant));
  }

  static clear() {
    localStorage.removeItem('tenant');
  }
}
