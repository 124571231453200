import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.escalation.fields.id'),
  },
  {
    name: 'rationale',
    label: i18n('entities.escalation.fields.rationale'),
  },
  {
    name: 'targetCompletion',
    label: i18n('entities.escalation.fields.targetCompletion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.escalation.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.escalation.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
