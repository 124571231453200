import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import companySelectors from 'src/modules/company/companySelectors';
import escalationSelectors from 'src/modules/escalation/escalationSelectors';
import initiativeSelectors from 'src/modules/initiative/initiativeSelectors';
import interactionSelectors from 'src/modules/interaction/interactionSelectors';
import objectiveSelectors from 'src/modules/objective/objectiveSelectors';
import researchSelectors from 'src/modules/research/researchSelectors';
import taskSelectors from 'src/modules/task/taskSelectors';
import menus from 'src/view/menus';
import {
  bgLight,
  bgMedium,
  createGreen,
  textMediumLight,
} from 'src/view/shared/Colours';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  position: relative;
  top: -2px;
  border-radius: 6em;
  paddingLeft: 2em;
  height: 3.2em;
  direction: rtl;
  box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.08), 0 6px 24px 0 rgba(0, 0, 0, 0.09);
  font-weight: bold;
  font-size: 12px;
  background-color: ${bgLight};
  color: ${textMediumLight};
  border-width: 0;
  :not(:disabled):hover,
  :hover {
    background-color: ${createGreen};
  }
  :focus {
    background-color: ${createGreen};
  }
`;

const StyledPlusOutlined = styled(FontAwesomeIcon)`
  font-size: 12px;
  color: ${bgLight};
  background-color: ${createGreen};
  border-radius: 8em;
  padding: 0.8em;
  position: relative;
  right: -10px;
`;

const Row = styled.div`
  padding: 0 7px 0 7px;
  border-radius: 6px;

  :hover {
    cursor: pointer;
    background-color: ${bgMedium};
  }
`;

const StyledLink = styled(Link)`
  color: ${textMediumLight};
  :hover {
    cursor: pointer;
    background-color: ${bgMedium};
    color: ${createGreen};
  }
`;

const CreateNew = (props) => {

  const hasPermissionToCreateObj = useSelector(
    objectiveSelectors.selectPermissionToCreate,
  );
  const hasPermissionToCreateInit = useSelector(
    initiativeSelectors.selectPermissionToCreate,
  );
  const hasPermissionToCreateInteraction = useSelector(
    interactionSelectors.selectPermissionToCreate,
  );
  const hasPermissionToCreateTask = useSelector(
    taskSelectors.selectPermissionToCreate,
  );
  const hasPermissionToCreateCompany = useSelector(
    companySelectors.selectPermissionToCreate,
  );
  const hasPermissionToCreateResearch = useSelector(
    researchSelectors.selectPermissionToCreate,
  );
  const hasPermissionToCreateEscalation = useSelector(
    escalationSelectors.selectPermissionToCreate,
  );

  const [isCreateNewModalVisible, setCreateNewModalVisible] = useState(false);
  const showsetCreateNewModalVisible = () => {
    setCreateNewModalVisible(!isCreateNewModalVisible);
  };

  // create a function called getIcon that takes a path and recursively searches through menus and children to find the icon. use map and filter functions to do this.
  const iconMap = {};
  for (const menu of menus) {
    iconMap[menu.path.replace('/', '')] = menu.icon;
    if (menu.children) {
      for (const child of menu.children) {
        iconMap[child.path.replace('/', '')] = child.icon;
      }
    }
  }

  const getIcon = (path: string): string | undefined => {
    return iconMap[path];
  };

  const Icon = ({ name, className = '' }) => (
    <span className={className} style={{
      margin: '4px',
      width: '18px',
      display: 'inline-block',
    }}>
      {getIcon(name)}
    </span>
  );

  const capitalise = (s) => (
    s[0].toUpperCase() + s.slice(1)
  );

  const createNewModalContent = (
    <>
      {hasPermissionToCreateInit && (
        <Row>
          <StyledLink key={'newInitiative'} to="/initiative/new">
            <Icon name='initiative' />
            {capitalise(i18n('entities.initiative.name'))}
          </StyledLink>
        </Row>
      )}
      {hasPermissionToCreateObj && (
        <Row>
          <StyledLink key={'newObjective'} to="/objective/new">
            <Icon name='objective' />
            {capitalise(i18n('entities.objective.name'))}
          </StyledLink>
        </Row>
      )}
      {hasPermissionToCreateInteraction && (
        <Row>
          <StyledLink key={'newInteraction'} to="/interaction/new">
            <Icon name='interaction' />
            {capitalise(i18n('entities.interaction.name'))}
          </StyledLink>
        </Row>
      )}
      {hasPermissionToCreateCompany && (
        <Row>
          <StyledLink key={'newCompany'} to="/company/new">
            <Icon name='company' />
            {capitalise(i18n('entities.company.name'))}
          </StyledLink>
        </Row>
      )}
      {hasPermissionToCreateTask && (
        <Row>
          <StyledLink key={'newTask'} to="/task/new">
            <Icon name='task' />
            {capitalise(i18n('entities.task.name'))}
          </StyledLink>
        </Row>
      )}
      {hasPermissionToCreateResearch && (
        <Row>
          <StyledLink key={'newResearch'} to="/research/new">
            <Icon name='research' />
            {capitalise(i18n('entities.research.name'))}
          </StyledLink>
        </Row>
      )}
      {hasPermissionToCreateEscalation && (
        <Row>
          <StyledLink key={'newEscalation'} to="/escalation/new">
            <Icon name='escalation' />
            {capitalise(i18n('entities.escalation.name'))}
          </StyledLink>
        </Row>
      )}
    </>
  );

  return (
    <Popover
      open={isCreateNewModalVisible}
      content={createNewModalContent}
      placement="bottomLeft" trigger="click"
      onOpenChange={showsetCreateNewModalVisible}
    >
      <StyledButton
        type="primary"
        icon={<StyledPlusOutlined icon={faPlus} />}
        htmlType="button"
      >
        <span style={{
          position: 'relative',
          top: '-10px',
          right: '2px',
        }}> {i18n('common.createNew')}</span>
      </StyledButton>
    </Popover>
  );
}

export default CreateNew
