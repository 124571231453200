import config from 'src/config';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: permissions.tenantCreate,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: permissions.tenantEdit,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/initiative',
    loader: () =>
      import('src/view/initiative/list/InitiativeListPage'),
    permissionRequired: permissions.initiativeRead,
    exact: true,
  },
  {
    path: '/initiative/new',
    loader: () =>
      import('src/view/initiative/form/InitiativeFormPage'),
    permissionRequired: permissions.initiativeCreate,
    exact: true,
  },
  {
    path: '/initiative/importer',
    loader: () =>
      import(
        'src/view/initiative/importer/InitiativeImporterPage'
      ),
    permissionRequired: permissions.initiativeImport,
    exact: true,
  },
  {
    path: '/initiative/:id/edit',
    loader: () =>
      import('src/view/initiative/form/InitiativeFormPage'),
    permissionRequired: permissions.initiativeEdit,
    exact: true,
  },
  {
    path: '/initiative/:id',
    loader: () =>
      import('src/view/initiative/view/InitiativeViewPage'),
    permissionRequired: permissions.initiativeRead,
    exact: true,
  },

  {
    path: '/company',
    loader: () =>
      import('src/view/company/list/CompanyListPage'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },
  {
    path: '/company/new',
    loader: () =>
      import('src/view/company/form/CompanyFormPage'),
    permissionRequired: permissions.companyCreate,
    exact: true,
  },
  {
    path: '/company/importer',
    loader: () =>
      import(
        'src/view/company/importer/CompanyImporterPage'
      ),
    permissionRequired: permissions.companyImport,
    exact: true,
  },
  {
    path: '/company/:id/edit',
    loader: () =>
      import('src/view/company/form/CompanyFormPage'),
    permissionRequired: permissions.companyEdit,
    exact: true,
  },
  {
    path: '/company/:id',
    loader: () =>
      import('src/view/company/view/CompanyViewPage'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },

  {
    path: '/objective',
    loader: () =>
      import('src/view/objective/list/ObjectiveListPage'),
    permissionRequired: permissions.objectiveRead,
    exact: true,
  },
  {
    path: '/objective/new',
    loader: () =>
      import('src/view/objective/form/ObjectiveFormPage'),
    permissionRequired: permissions.objectiveCreate,
    exact: true,
  },
  {
    path: '/objective/importer',
    loader: () =>
      import(
        'src/view/objective/importer/ObjectiveImporterPage'
      ),
    permissionRequired: permissions.objectiveImport,
    exact: true,
  },
  {
    path: '/objective/:id/edit',
    loader: () =>
      import('src/view/objective/form/ObjectiveFormPage'),
    permissionRequired: permissions.objectiveEdit,
    exact: true,
  },
  {
    path: '/objective/:id',
    loader: () =>
      import('src/view/objective/view/ObjectiveViewPage'),
    permissionRequired: permissions.objectiveRead,
    exact: true,
  },

  {
    path: '/interaction',
    loader: () =>
      import('src/view/interaction/list/InteractionListPage'),
    permissionRequired: permissions.interactionRead,
    exact: true,
  },
  {
    path: '/interaction/new',
    loader: () =>
      import('src/view/interaction/form/InteractionFormPage'),
    permissionRequired: permissions.interactionCreate,
    exact: true,
  },
  {
    path: '/interaction/importer',
    loader: () =>
      import(
        'src/view/interaction/importer/InteractionImporterPage'
      ),
    permissionRequired: permissions.interactionImport,
    exact: true,
  },
  {
    path: '/interaction/importer/calendar',
    loader: () =>
      import('src/view/interaction/importer/InteractionCalendarImporterPage'),
    permissionRequired: permissions.interactionRead,
    exact: true,
  },
  {
    path: '/interaction/importer/calendar/msAuth',
    loader: () =>
      import('src/view/interaction/importer/InteractionCalendarImporterPage'),
    permissionRequired: permissions.interactionRead,
    exact: true,
  },
  {
    path: '/interaction/:id/edit',
    loader: () =>
      import('src/view/interaction/form/InteractionFormPage'),
    permissionRequired: permissions.interactionEdit,
    exact: true,
  },
  {
    path: '/interaction/:id',
    loader: () =>
      import('src/view/interaction/view/InteractionViewPage'),
    permissionRequired: permissions.interactionRead,
    exact: true,
  },

  {
    path: '/task',
    loader: () =>
      import('src/view/task/list/TaskListPage'),
    permissionRequired: permissions.taskRead,
    exact: true,
  },
  {
    path: '/task/filter/:companyID',
    loader: () =>
      import('src/view/task/list/TaskListFilterPage'),
    permissionRequired: permissions.taskRead,
    exact: true,
  },
  {
    path: '/task/new',
    loader: () =>
      import('src/view/task/form/TaskFormPage'),
    permissionRequired: permissions.taskCreate,
    exact: true,
  },
  {
    path: '/task/importer',
    loader: () =>
      import(
        'src/view/task/importer/TaskImporterPage'
      ),
    permissionRequired: permissions.taskImport,
    exact: true,
  },
  {
    path: '/task/:id/edit',
    loader: () =>
      import('src/view/task/list/TaskListPage'),
    permissionRequired: permissions.taskEdit,
    exact: true,
  },
  {
    path: '/task/:id',
    loader: () =>
      import('src/view/task/list/TaskListPage'),
    permissionRequired: permissions.taskRead,
    exact: true,
  },

  {
    path: '/research',
    loader: () =>
      import('src/view/research/list/ResearchListPage'),
    permissionRequired: permissions.researchRead,
    exact: true,
  },
  {
    path: '/research/new',
    loader: () =>
      import('src/view/research/form/ResearchFormPage'),
    permissionRequired: permissions.researchCreate,
    exact: true,
  },
  {
    path: '/research/importer',
    loader: () =>
      import(
        'src/view/research/importer/ResearchImporterPage'
      ),
    permissionRequired: permissions.researchImport,
    exact: true,
  },
  {
    path: '/research/:id/edit',
    loader: () =>
      import('src/view/research/form/ResearchFormPage'),
    permissionRequired: permissions.researchEdit,
    exact: true,
  },
  {
    path: '/research/:id',
    loader: () =>
      import('src/view/research/view/ResearchViewPage'),
    permissionRequired: permissions.researchRead,
    exact: true,
  },

  {
    path: '/voting',
    loader: () =>
      import('src/view/voting/list/VotingListPage'),
    permissionRequired: permissions.votingRead,
    exact: true,
  },
  {
    path: '/voting/new',
    loader: () =>
      import('src/view/voting/form/VotingFormPage'),
    permissionRequired: permissions.votingCreate,
    exact: true,
  },
  {
    path: '/voting/importer',
    loader: () =>
      import(
        'src/view/voting/importer/VotingImporterPage'
      ),
    permissionRequired: permissions.votingImport,
    exact: true,
  },
  {
    path: '/voting/:id/edit',
    loader: () =>
      import('src/view/voting/form/VotingFormPage'),
    permissionRequired: permissions.votingEdit,
    exact: true,
  },
  {
    path: '/voting/:id',
    loader: () =>
      import('src/view/voting/view/VotingViewPage'),
    permissionRequired: permissions.votingRead,
    exact: true,
  },

  {
    path: '/contact',
    loader: () =>
      import('src/view/contact/list/ContactListPage'),
    permissionRequired: permissions.contactRead,
    exact: true,
  },
  {
    path: '/contact/new',
    loader: () =>
      import('src/view/contact/form/ContactFormPage'),
    permissionRequired: permissions.contactCreate,
    exact: true,
  },
  {
    path: '/contact/importer',
    loader: () =>
      import(
        'src/view/contact/importer/ContactImporterPage'
      ),
    permissionRequired: permissions.contactImport,
    exact: true,
  },
  {
    path: '/contact/:id/edit',
    loader: () =>
      import('src/view/contact/form/ContactFormPage'),
    permissionRequired: permissions.contactEdit,
    exact: true,
  },
  {
    path: '/contact/:id',
    loader: () =>
      import('src/view/contact/view/ContactViewPage'),
    permissionRequired: permissions.contactRead,
    exact: true,
  },

  {
    path: '/fund',
    loader: () =>
      import('src/view/fund/list/FundListPage'),
    permissionRequired: permissions.fundRead,
    exact: true,
  },
  {
    path: '/fund/new',
    loader: () =>
      import('src/view/fund/form/FundFormPage'),
    permissionRequired: permissions.fundCreate,
    exact: true,
  },
  {
    path: '/fund/importer',
    loader: () =>
      import(
        'src/view/fund/importer/FundImporterPage'
      ),
    permissionRequired: permissions.fundImport,
    exact: true,
  },
  {
    path: '/fund/:id/importer',
    loader: () =>
      import('src/view/fund/importer/FundHoldingImporterPage'),
    permissionRequired: permissions.fundEdit,
    exact: true,
  },
  {
    path: '/fund/:id/edit',
    loader: () =>
      import('src/view/fund/form/FundFormPage'),
    permissionRequired: permissions.fundEdit,
    exact: true,
  },
  {
    path: '/fund/:id',
    loader: () =>
      import('src/view/fund/view/FundViewPage'),
    permissionRequired: permissions.fundRead,
    exact: true,
  },

  {
    path: '/tag',
    loader: () =>
      import('src/view/tag/list/TagListPage'),
    permissionRequired: permissions.tagRead,
    exact: true,
  },
  {
    path: '/tag/new',
    loader: () =>
      import('src/view/tag/form/TagFormPage'),
    permissionRequired: permissions.tagCreate,
    exact: true,
  },
  {
    path: '/tag/importer',
    loader: () =>
      import(
        'src/view/tag/importer/TagImporterPage'
      ),
    permissionRequired: permissions.tagImport,
    exact: true,
  },
  {
    path: '/tag/:id/edit',
    loader: () =>
      import('src/view/tag/form/TagFormPage'),
    permissionRequired: permissions.tagEdit,
    exact: true,
  },
  {
    path: '/tag/:id',
    loader: () =>
      import('src/view/tag/view/TagViewPage'),
    permissionRequired: permissions.tagRead,
    exact: true,
  },

  {
    path: '/region',
    loader: () =>
      import('src/view/region/list/RegionListPage'),
    permissionRequired: permissions.regionRead,
    exact: true,
  },
  {
    path: '/region/new',
    loader: () =>
      import('src/view/region/form/RegionFormPage'),
    permissionRequired: permissions.regionCreate,
    exact: true,
  },
  {
    path: '/region/importer',
    loader: () =>
      import(
        'src/view/region/importer/RegionImporterPage'
      ),
    permissionRequired: permissions.regionImport,
    exact: true,
  },
  {
    path: '/region/:id/edit',
    loader: () =>
      import('src/view/region/form/RegionFormPage'),
    permissionRequired: permissions.regionEdit,
    exact: true,
  },
  {
    path: '/region/:id',
    loader: () =>
      import('src/view/region/view/RegionViewPage'),
    permissionRequired: permissions.regionRead,
    exact: true,
  },

  {
    path: '/team',
    loader: () =>
      import('src/view/team/list/TeamListPage'),
    permissionRequired: permissions.teamRead,
    exact: true,
  },
  {
    path: '/team/new',
    loader: () =>
      import('src/view/team/form/TeamFormPage'),
    permissionRequired: permissions.teamCreate,
    exact: true,
  },
  {
    path: '/team/importer',
    loader: () =>
      import(
        'src/view/team/importer/TeamImporterPage'
      ),
    permissionRequired: permissions.teamImport,
    exact: true,
  },
  {
    path: '/team/:id/edit',
    loader: () =>
      import('src/view/team/form/TeamFormPage'),
    permissionRequired: permissions.teamEdit,
    exact: true,
  },
  {
    path: '/team/:id',
    loader: () =>
      import('src/view/team/view/TeamViewPage'),
    permissionRequired: permissions.teamRead,
    exact: true,
  },

  {
    path: '/outcome',
    loader: () =>
      import('src/view/outcome/list/OutcomeListPage'),
    permissionRequired: permissions.outcomeRead,
    exact: true,
  },
  {
    path: '/outcome/new',
    loader: () =>
      import('src/view/outcome/form/OutcomeFormPage'),
    permissionRequired: permissions.outcomeCreate,
    exact: true,
  },
  {
    path: '/outcome/importer',
    loader: () =>
      import(
        'src/view/outcome/importer/OutcomeImporterPage'
      ),
    permissionRequired: permissions.outcomeImport,
    exact: true,
  },
  {
    path: '/outcome/:id/edit',
    loader: () =>
      import('src/view/outcome/form/OutcomeFormPage'),
    permissionRequired: permissions.outcomeEdit,
    exact: true,
  },
  {
    path: '/outcome/:id',
    loader: () =>
      import('src/view/outcome/view/OutcomeViewPage'),
    permissionRequired: permissions.outcomeRead,
    exact: true,
  },

  {
    path: '/following',
    loader: () =>
      import('src/view/following/list/FollowingListPage'),
    permissionRequired: permissions.followingRead,
    exact: true,
  },
  {
    path: '/following/new',
    loader: () =>
      import('src/view/following/form/FollowingFormPage'),
    permissionRequired: permissions.followingCreate,
    exact: true,
  },
  {
    path: '/following/importer',
    loader: () =>
      import(
        'src/view/following/importer/FollowingImporterPage'
      ),
    permissionRequired: permissions.followingImport,
    exact: true,
  },
  {
    path: '/following/:id/edit',
    loader: () =>
      import('src/view/following/form/FollowingFormPage'),
    permissionRequired: permissions.followingEdit,
    exact: true,
  },
  {
    path: '/following/:id',
    loader: () =>
      import('src/view/following/view/FollowingViewPage'),
    permissionRequired: permissions.followingRead,
    exact: true,
  },
  {
    path: '/search',
    loader: () =>
      import('src/view/search/list/SearchListPage'),
    permissionRequired: permissions.searchRead,
    exact: true,
  },
  {
    path: '/issues',
    loader: () =>
      import('src/view/issues/list/IssuesListPage'),
    permissionRequired: permissions.issuesRead,
    exact: true,
  },
  {
    path: '/issues/new',
    loader: () =>
      import('src/view/issues/form/IssuesFormPage'),
    permissionRequired: permissions.issuesCreate,
    exact: true,
  },
  {
    path: '/issues/importer',
    loader: () =>
      import(
        'src/view/issues/importer/IssuesImporterPage'
      ),
    permissionRequired: permissions.issuesImport,
    exact: true,
  },
  {
    path: '/issues/:id/edit',
    loader: () =>
      import('src/view/issues/form/IssuesFormPage'),
    permissionRequired: permissions.issuesEdit,
    exact: true,
  },
  {
    path: '/issues/:id',
    loader: () =>
      import('src/view/issues/view/IssuesViewPage'),
    permissionRequired: permissions.issuesRead,
    exact: true,
  },
  {
    path: '/report',
    loader: () =>
      import('src/view/report/list/ReportListPage'),
    permissionRequired: permissions.reportRead,
    exact: true,
  },
  {
    path: '/report/custom',
    loader: () =>
      import('src/view/report/view/CustomReportPage'),
    permissionRequired: permissions.reportRead,
    exact: true,
  },
  {
    path: '/casestudy',
    loader: () =>
      import('src/view/casestudy/list/CasestudyListPage'),
    permissionRequired: permissions.casestudyRead,
    exact: true,
  },
  {
    path: '/casestudy/new',
    loader: () =>
      import('src/view/casestudy/form/CasestudyFormPage'),
    permissionRequired: permissions.casestudyCreate,
    exact: true,
  },
  {
    path: '/casestudy/importer',
    loader: () =>
      import(
        'src/view/casestudy/importer/CasestudyImporterPage'
      ),
    permissionRequired: permissions.casestudyImport,
    exact: true,
  },
  {
    path: '/casestudy/:id/edit',
    loader: () =>
      import('src/view/casestudy/form/CasestudyFormPage'),
    permissionRequired: permissions.casestudyEdit,
    exact: true,
  },
  {
    path: '/casestudy/:id',
    loader: () =>
      import('src/view/casestudy/view/CasestudyViewPage'),
    permissionRequired: permissions.casestudyRead,
    exact: true,
  },
  {
    path: '/benchmarking',
    loader: () =>
      import('src/view/benchmarking/list/BenchmarkingListPage'),
    permissionRequired: permissions.benchmarkingRead,
    exact: true,
  },
  {
    path: '/theme-lens',
    loader: () =>
      import('src/view/themeLens/view/ThemeLensViewPage'),
    permissionRequired: permissions.themeLensRead,
    exact: true,
  },
  {
    path: '/benchmark-score',
    loader: () =>
      import('src/view/benchmarkScore/list/BenchmarkScoreListPage'),
    permissionRequired: permissions.benchmarkScoreRead,
    exact: true,
  },
  {
    path: '/benchmark-score/new',
    loader: () =>
      import('src/view/benchmarkScore/form/BenchmarkScoreFormPage'),
    permissionRequired: permissions.benchmarkScoreCreate,
    exact: true,
  },
  {
    path: '/benchmark-score/importer',
    loader: () =>
      import(
        'src/view/benchmarkScore/importer/BenchmarkScoreImporterPage'
      ),
    permissionRequired: permissions.benchmarkScoreImport,
    exact: true,
  },
  {
    path: '/benchmark-score/:id/edit',
    loader: () =>
      import('src/view/benchmarkScore/form/BenchmarkScoreFormPage'),
    permissionRequired: permissions.benchmarkScoreEdit,
    exact: true,
  },
  {
    path: '/benchmark-score/:id',
    loader: () =>
      import('src/view/benchmarkScore/view/BenchmarkScoreViewPage'),
    permissionRequired: permissions.benchmarkScoreRead,
    exact: true,
  },

  {
    path: '/benchmark-study',
    loader: () =>
      import('src/view/benchmarkStudy/list/BenchmarkStudyListPage'),
    permissionRequired: permissions.benchmarkStudyRead,
    exact: true,
  },
  {
    path: '/benchmark-study/new',
    loader: () =>
      import('src/view/benchmarkStudy/form/BenchmarkStudyFormPage'),
    permissionRequired: permissions.benchmarkStudyCreate,
    exact: true,
  },
  {
    path: '/benchmark-study/importer',
    loader: () =>
      import(
        'src/view/benchmarkStudy/importer/BenchmarkStudyImporterPage'
      ),
    permissionRequired: permissions.benchmarkStudyImport,
    exact: true,
  },
  {
    path: '/benchmark-study/:id/edit',
    loader: () =>
      import('src/view/benchmarkStudy/form/BenchmarkStudyFormPage'),
    permissionRequired: permissions.benchmarkStudyEdit,
    exact: true,
  },
  {
    path: '/benchmark-study/:id',
    loader: () =>
      import('src/view/benchmarkStudy/view/BenchmarkStudyViewPage'),
    permissionRequired: permissions.benchmarkStudyRead,
    exact: true,
  },

  {
    path: '/escalation',
    loader: () =>
      import('src/view/escalation/list/EscalationListPage'),
    permissionRequired: permissions.escalationRead,
    exact: true,
  },
  {
    path: '/escalation/new',
    loader: () =>
      import('src/view/escalation/form/EscalationFormPage'),
    permissionRequired: permissions.escalationCreate,
    exact: true,
  },
  {
    path: '/escalation/importer',
    loader: () =>
      import(
        'src/view/escalation/importer/EscalationImporterPage'
      ),
    permissionRequired: permissions.escalationImport,
    exact: true,
  },
  {
    path: '/escalation/:id/edit',
    loader: () =>
      import('src/view/escalation/form/EscalationFormPage'),
    permissionRequired: permissions.escalationEdit,
    exact: true,
  },
  {
    path: '/escalation/:id',
    loader: () =>
      import('src/view/escalation/view/EscalationViewPage'),
    permissionRequired: permissions.escalationRead,
    exact: true,
  },
  {
    path: '/watchlist',
    loader: () =>
      import('src/view/watchlist/list/WatchlistListPage'),
    permissionRequired: permissions.watchlistRead,
    exact: true,
  },
  {
    path: '/watchlist/new',
    loader: () =>
      import('src/view/watchlist/form/WatchlistFormPage'),
    permissionRequired: permissions.watchlistCreate,
    exact: true,
  },
  {
    path: '/watchlist/importer',
    loader: () =>
      import(
        'src/view/watchlist/importer/WatchlistImporterPage'
      ),
    permissionRequired: permissions.watchlistImport,
    exact: true,
  },
  {
    path: '/watchlist/:id/edit',
    loader: () =>
      import('src/view/watchlist/form/WatchlistFormPage'),
    permissionRequired: permissions.watchlistEdit,
    exact: true,
  },
  {
    path: '/watchlist/:id',
    loader: () =>
      import('src/view/watchlist/view/WatchlistViewPage'),
    permissionRequired: permissions.watchlistRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  config.isSignupEnabled && {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
