import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import dayjs from 'dayjs';

export default [
  {
    name: 'rationale',
    label: i18n('entities.escalation.fields.rationale'),
    schema: schemas.string(
      i18n('entities.escalation.fields.rationale'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'targetCompletion',
    label: i18n('entities.escalation.fields.targetCompletion'),
    schema: schemas.date(
      i18n('entities.escalation.fields.targetCompletion'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value,
  },
];