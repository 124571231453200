import { useEffect, useRef } from 'react';
import { useLocation as useReactRouterLocation } from 'react-router-dom';
import { useAppDispatch } from 'src/modules/hooks';
import interactionListActions from './interaction/list/interactionListActions';
import objectiveListActions from './objective/list/objectiveListActions';
import researchListActions from './research/list/researchListActions';
import taskListActions from './task/list/taskListActions';
import votingListActions from './voting/list/votingListActions';
import benchmarkScoreListActions from './benchmarkScore/list/benchmarkScoreListActions';
import benchmarkStudyListActions from './benchmarkStudy/list/benchmarkStudyListActions';
import casestudyListActions from './casestudy/list/casestudyListActions';
import watchlistListActions from './watchlist/list/watchlistListActions';


export const HistoryListener = ({ children }) => {
    const location = useReactRouterLocation();
    const lastLocation = useRef('');
    const dispatch = useAppDispatch();

    const clearReduxState = (pathname: string) => {
        //MEMO: for filter and list data reset correct work [Objective/Interaction/etc.]ListFilter.tsx must contain
        //hook that will update filter form values when filter data reset is done in HistoryListener:
        //useEffect(() => {
        //     form.reset({...emptyValues, ...(rawFilter ? rawFilter : {})});
        //}, [rawFilter]);

        switch (pathname) {
            case '/interaction':
                dispatch(interactionListActions.doReset());
                break;
            case '/objective':
                dispatch(objectiveListActions.doReset());
                break;
            case '/research':
                dispatch(researchListActions.doReset());
                break;
            case '/voting':
                dispatch(votingListActions.doReset());
                break;
            case '/task':
                dispatch(taskListActions.doReset());
                break;
            case '/benchmark-score':
                dispatch(benchmarkScoreListActions.doReset());
                break;
            case '/benchmark-study':
                dispatch(benchmarkStudyListActions.doReset());
                break;
            case '/casestudy':
                dispatch(casestudyListActions.doReset());
                break;
            case '/watchlist':
                dispatch(watchlistListActions.doReset());
                break;
    
            default:
                break;
        }
    }

    useEffect(() => {
        //redux stored filter state and lists data will be reset if not going back to same page and location.search is empty
        if (location.pathname !== lastLocation.current && !location.search) {
            clearReduxState(location.pathname);
        }

        lastLocation.current = location.pathname;
    }, [location]);

    return children;
};
