import list from 'src/modules/company/list/companyListReducers';
import form from 'src/modules/company/form/companyFormReducers';
import view from 'src/modules/company/view/companyViewReducers';
import destroy from 'src/modules/company/destroy/companyDestroyReducers';
import { listedImporterReducer, unlistedImporterReducer } from 'src/modules/company/importer/companyImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  listedImporter: listedImporterReducer,
  unlistedImporter: unlistedImporterReducer,
});
