import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import interactionEnumerators from 'src/modules/interaction/interactionEnumerators';
import dayjs from 'dayjs';
import config from 'src/config';

export default [
  {
    name: 'ISIN',
    label: i18n('entities.interaction.fields.isin'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.isin'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'interactionDate',
    label: i18n('entities.interaction.fields.interactionDate'),
    schema: schemas.date(
      i18n('entities.interaction.fields.interactionDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'internalAttendees',
    label: i18n('entities.interaction.fields.internalAttendees'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.internalAttendees'),
      {},
    ),
  },
  {
    name: 'externalAttendees',
    label: i18n('entities.interaction.fields.externalAttendees'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.externalAttendees'),
      {},
    ),
  },
  {
    name: 'meetingType',
    label: i18n('entities.interaction.fields.meetingType'),
    schema: schemas.enumerator(
      i18n('entities.interaction.fields.meetingType'),
      {
        "options": interactionEnumerators.meetingType,
        "required": true
      },
    ),
  },
  {
    name: 'subject',
    label: i18n('entities.interaction.fields.subject'),
    schema: schemas.string(
      i18n('entities.interaction.fields.subject'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'purpose',
    label: i18n('entities.interaction.fields.purpose'),
    schema: schemas.enumerator(
      i18n('entities.interaction.fields.purpose'),
      {
        "options": interactionEnumerators.purpose,
        "required": true
      },
    ),
  },
  {
    name: 'subTheme',
    label: i18n('entities.interaction.fields.subTheme'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.subTheme'),
      {},
    ),
  },
  {
    name: 'meetingSummary',
    label: i18n('entities.interaction.fields.meetingSummary'),
    schema: schemas.string(
      i18n('entities.interaction.fields.meetingSummary'),
      {},
    ),
  },
  {
    name: 'attachments',
    label: i18n('entities.interaction.fields.attachments'),
    schema: schemas.files(
      i18n('entities.interaction.fields.attachments'),
      {},
    ),
  },
  {
    name: 'feeling',
    label: i18n('entities.interaction.fields.feeling'),
    schema: schemas.integer(
      i18n('entities.interaction.fields.feeling'),
      {
        "min": 1,
        "max": 5
      },
    ),
  },
  {
    name: 'objectiveLink',
    label: i18n('entities.interaction.fields.objectiveLink'),
    schema: schemas.relationToOne(
      i18n('entities.interaction.fields.objectiveLink'),
      {},
    ),
  },
  {
    name: 'investmentAction',
    label: i18n('entities.interaction.fields.investmentAction'),
    schema: schemas.enumerator(
      i18n('entities.interaction.fields.investmentAction'),
      {
        "options": interactionEnumerators.investmentAction
      },
    ),
  },
  {
    name: 'stewardshipEscalation',
    label: i18n('entities.interaction.fields.stewardshipEscalation'),
    schema: schemas.booleanString(
      i18n('entities.interaction.fields.stewardshipEscalation'),
      {}
    ),
    render: value => (
      typeof value === 'string' &&
      ['yes', 'y'].includes(value.toLowerCase()) ?
        i18n('common.yes') :
        i18n('common.no')
    )
  },
  {
    name: 'outcome',
    label: i18n('entities.interaction.fields.outcome'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.outcome'),
      {},
    ),
  },
  {
    name: 'tags',
    label: i18n('entities.interaction.fields.tags'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.tags'),
      {},
    ),
  },
  {
    name: 'arrangedBy',
    label: i18n('entities.interaction.fields.arrangedBy'),
    schema: schemas.enumerator(
      i18n('entities.interaction.fields.arrangedBy'),
      {
        "options": interactionEnumerators.arrangedBy,
        "required" : config.complianceEnabled
      },
    ),
  },
  {
    name: 'nameOfBroker',
    label: i18n('entities.interaction.fields.nameOfBroker'),
    schema: schemas.string(
      i18n('entities.interaction.fields.nameOfBroker'),
      {
        "required" : config.complianceEnabled,
        "dependsOn": {field: 'arrangedBy', values: ['broker', 'house_broker']}
      },
    ),
  },
  ...[
    'brokerPresent',
    'openToAllInvestors',
    'closedIssuerPeriod',
    'madeInsider',
    'esgIssuesDiscussed',
    'under200mMarketCap'].map(item => (
    {
      name: item,
      label: i18n(`entities.interaction.fields.${item}`),
      schema: schemas.booleanString(
        i18n(`entities.interaction.fields.${item}`),
        {
          "required" : config.complianceEnabled
        }
      ),
      render: value => (
        typeof value === 'string' &&
        ['yes', 'y'].includes(value.toLowerCase()) ?
          i18n('common.yes') :
          i18n('common.no')
      )
    }
  )),
  {
    name: 'tasks',
    label: i18n('entities.interaction.fields.tasks'),
    schema: schemas.relationToMany(
      i18n('entities.interaction.fields.tasks'),
      {},
    ),
  },
];
