import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantCreate: {
        id: 'tenantCreate',
        allowedRoles: [roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.globaladmin, roles.tenantadmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      tenantAutocomplete: {
        id: 'tenantAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.tenantadmin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.tenantadmin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.tenantadmin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.tenantadmin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.tenantadmin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      settingsThemeEdit: {
        id: 'settingsThemeEdit',
        allowedRoles: [roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      initiativeImport: {
        id: 'initiativeImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      initiativeCreate: {
        id: 'initiativeCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      initiativeEdit: {
        id: 'initiativeEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      initiativeDestroy: {
        id: 'initiativeDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      initiativeRead: {
        id: 'initiativeRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      initiativeAutocomplete: {
        id: 'initiativeAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      companyImport: {
        id: 'companyImport',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      companyCreate: {
        id: 'companyCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      companyEdit: {
        id: 'companyEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      companyDestroy: {
        id: 'companyDestroy',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      companyRead: {
        id: 'companyRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      companyAutocomplete: {
        id: 'companyAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      objectiveImport: {
        id: 'objectiveImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      objectiveCreate: {
        id: 'objectiveCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.objectiveAttachments,
        ],
      },
      objectiveEdit: {
        id: 'objectiveEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.objectiveAttachments,
        ],
      },
      objectiveDestroy: {
        id: 'objectiveDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.objectiveAttachments,
        ],
      },
      objectiveRead: {
        id: 'objectiveRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      objectiveAutocomplete: {
        id: 'objectiveAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      interactionImport: {
        id: 'interactionImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      interactionCreate: {
        id: 'interactionCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.interactionAttachments,
        ],
      },
      interactionEdit: {
        id: 'interactionEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.interactionAttachments,
        ],
      },
      interactionDestroy: {
        id: 'interactionDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.interactionAttachments,
        ],
      },
      interactionRead: {
        id: 'interactionRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      interactionAutocomplete: {
        id: 'interactionAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      taskImport: {
        id: 'taskImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      taskCreate: {
        id: 'taskCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.taskAttachments,
        ],
      },
      taskEdit: {
        id: 'taskEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.taskAttachments,
        ],
      },
      taskDestroy: {
        id: 'taskDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.taskAttachments,
        ],
      },
      taskRead: {
        id: 'taskRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      taskAutocomplete: {
        id: 'taskAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      researchImport: {
        id: 'researchImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      researchCreate: {
        id: 'researchCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.researchAttachment,
        ],
      },
      researchEdit: {
        id: 'researchEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.researchAttachment,
        ],
      },
      researchDestroy: {
        id: 'researchDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.researchAttachment,
        ],
      },
      researchRead: {
        id: 'researchRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      researchAutocomplete: {
        id: 'researchAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      votingImport: {
        id: 'votingImport',
        allowedRoles: [roles.voting, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      votingCreate: {
        id: 'votingCreate',
        allowedRoles: [roles.voting, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.votingAttachments,
        ],
      },
      votingEdit: {
        id: 'votingEdit',
        allowedRoles: [roles.voting, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.votingAttachments,
        ],
      },
      votingDestroy: {
        id: 'votingDestroy',
        allowedRoles: [roles.voting, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [
          storage.votingAttachments,
        ],
      },
      votingRead: {
        id: 'votingRead',
        allowedRoles: [roles.voting, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      votingAutocomplete: {
        id: 'votingAutocomplete',
        allowedRoles: [roles.voting, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      contactImport: {
        id: 'contactImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      contactCreate: {
        id: 'contactCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      contactEdit: {
        id: 'contactEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      contactDestroy: {
        id: 'contactDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      contactRead: {
        id: 'contactRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      contactAutocomplete: {
        id: 'contactAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      fundImport: {
        id: 'fundImport',
        allowedRoles: [roles.globaladmin, roles.funds],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      fundCreate: {
        id: 'fundCreate',
        allowedRoles: [roles.globaladmin, roles.funds],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      fundEdit: {
        id: 'fundEdit',
        allowedRoles: [roles.globaladmin, roles.funds],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      fundDestroy: {
        id: 'fundDestroy',
        allowedRoles: [roles.globaladmin, roles.funds],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      fundRead: {
        id: 'fundRead',
        allowedRoles: [roles.funds, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      fundAutocomplete: {
        id: 'fundAutocomplete',
        allowedRoles: [roles.funds, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      tagImport: {
        id: 'tagImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      tagCreate: {
        id: 'tagCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      tagEdit: {
        id: 'tagEdit',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      tagDestroy: {
        id: 'tagDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      tagRead: {
        id: 'tagRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      tagAutocomplete: {
        id: 'tagAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      regionImport: {
        id: 'regionImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      regionCreate: {
        id: 'regionCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      regionEdit: {
        id: 'regionEdit',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      regionDestroy: {
        id: 'regionDestroy',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      regionRead: {
        id: 'regionRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      regionAutocomplete: {
        id: 'regionAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      teamImport: {
        id: 'teamImport',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      teamCreate: {
        id: 'teamCreate',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      teamEdit: {
        id: 'teamEdit',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      teamDestroy: {
        id: 'teamDestroy',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      teamRead: {
        id: 'teamRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      teamAutocomplete: {
        id: 'teamAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      outcomeImport: {
        id: 'outcomeImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      outcomeCreate: {
        id: 'outcomeCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      outcomeEdit: {
        id: 'outcomeEdit',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      outcomeDestroy: {
        id: 'outcomeDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      outcomeRead: {
        id: 'outcomeRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      outcomeAutocomplete: {
        id: 'outcomeAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      followingImport: {
        id: 'followingImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      followingCreate: {
        id: 'followingCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      followingEdit: {
        id: 'followingEdit',
        allowedRoles: [roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      followingDestroy: {
        id: 'followingDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      followingRead: {
        id: 'followingRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      followingAutocomplete: {
        id: 'followingAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      searchRead: {
        id: 'searchRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      issuesImport: {
        id: 'issuesImport',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      issuesCreate: {
        id: 'issuesCreate',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      issuesEdit: {
        id: 'issuesEdit',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      issuesDestroy: {
        id: 'issuesDestroy',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      issuesRead: {
        id: 'issuesRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      issuesAutocomplete: {
        id: 'issuesAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      reportRead: {
        id: 'reportRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      reportRun: {
        id: 'reportRun',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      analyticsReportingCustomReport: {
        id: 'analyticsReportingCustomReport',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      casestudyImport: {
        id: 'casestudyImport',
        allowedRoles: [roles.casestudies, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      casestudyCreate: {
        id: 'casestudyCreate',
        allowedRoles: [roles.casestudies, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      casestudyEdit: {
        id: 'casestudyEdit',
        allowedRoles: [roles.casestudies, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      casestudyDestroy: {
        id: 'casestudyDestroy',
        allowedRoles: [roles.casestudies, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      casestudyRead: {
        id: 'casestudyRead',
        allowedRoles: [roles.casestudies, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      casestudyAutocomplete: {
        id: 'casestudyAutocomplete',
        allowedRoles: [roles.casestudies, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      benchmarkingRead: {
        id: 'benchmarkingRead',
        allowedRoles: [roles.readonly, roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      themeLensRead: {
        id: 'themeLensRead',
        allowedRoles: [roles.readonly, roles.admin, roles.user, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      benchmarkScoreImport: {
        id: 'benchmarkScoreImport',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      benchmarkScoreCreate: {
        id: 'benchmarkScoreCreate',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      benchmarkScoreEdit: {
        id: 'benchmarkScoreEdit',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      benchmarkScoreDestroy: {
        id: 'benchmarkScoreDestroy',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      benchmarkScoreRead: {
        id: 'benchmarkScoreRead',
        allowedRoles: [roles.readonly, roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      benchmarkScoreAutocomplete: {
        id: 'benchmarkScoreAutocomplete',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      benchmarkStudyImport: {
        id: 'benchmarkStudyImport',
        allowedRoles: [roles.globaladmin, roles.tenantadmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      benchmarkStudyCreate: {
        id: 'benchmarkStudyCreate',
        allowedRoles: [roles.globaladmin, roles.tenantadmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      benchmarkStudyEdit: {
        id: 'benchmarkStudyEdit',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      benchmarkStudyDestroy: {
        id: 'benchmarkStudyDestroy',
        allowedRoles: [roles.globaladmin, roles.tenantadmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      benchmarkStudyRead: {
        id: 'benchmarkStudyRead',
        allowedRoles: [roles.readonly, roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      benchmarkStudyAutocomplete: {
        id: 'benchmarkStudyAutocomplete',
        allowedRoles: [roles.benchmarking, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },

      escalationImport: {
        id: 'escalationImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      escalationCreate: {
        id: 'escalationCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      escalationEdit: {
        id: 'escalationEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      escalationDestroy: {
        id: 'escalationDestroy',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      escalationRead: {
        id: 'escalationRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      escalationAutocomplete: {
        id: 'escalationAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      watchlistImport: {
        id: 'watchlistImport',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      watchlistCreate: {
        id: 'watchlistCreate',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      watchlistEdit: {
        id: 'watchlistEdit',
        allowedRoles: [roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      watchlistDestroy: {
        id: 'watchlistDestroy',
        allowedRoles: [roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
        allowedStorage: [

        ],
      },
      watchlistRead: {
        id: 'watchlistRead',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
      watchlistAutocomplete: {
        id: 'watchlistAutocomplete',
        allowedRoles: [roles.readonly, roles.user, roles.admin, roles.globaladmin],
        allowedPlans: [plans.free, plans.standard, plans.professional, plans.enterprise],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
