import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/watchlist/importer/watchlistImporterSelectors';
import WatchlistService from 'src/modules/watchlist/watchlistService';
import fields from 'src/modules/watchlist/importer/watchlistImporterFields';
import { i18n } from 'src/i18n';

const watchlistImporterActions = importerActions(
  'WATCHLIST_IMPORTER',
  selectors,
  WatchlistService.import,
  fields,
  i18n('entities.watchlist.importer.fileName'),
);

export default watchlistImporterActions;