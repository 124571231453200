import { i18n } from 'src/i18n';
import schemas from 'src/modules/shared/yup/yupImporterSchemas';

export default [
  {
    name: 'entityType',
    label: i18n('entities.company.fields.entityType'),
    schema: schemas.string(
      i18n('entities.company.fields.entityType'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'companyName',
    label: i18n('entities.company.fields.companyName'),
    schema: schemas.string(
      i18n('entities.company.fields.companyName'),
      {
        "required": true,
        "min": 2,
        "max": 100
      },
    ),
  },
  {
    name: 'address',
    label: i18n('entities.company.fields.address'),
    schema: schemas.string(
      i18n('entities.company.fields.address'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.company.fields.country'),
    schema: schemas.string(
      i18n('entities.company.fields.country'),
      {
        "required": true,
        "min": 2,
        "max": 3
      },
    ),
  },
  {
    name: 'sector',
    label: i18n('entities.company.fields.sector'),
    schema: schemas.string(
      i18n('entities.company.fields.sector'),
      {},
    ),
  },
  {
    name: 'industry',
    label: i18n('entities.company.fields.industry'),
    schema: schemas.string(
      i18n('entities.company.fields.industry'),
      {},
    ),
  },
  {
    name: 'logo',
    label: i18n('entities.company.fields.logo'),
    schema: schemas.string(
      i18n('entities.company.fields.logo'),
      {},
    ),
  },
  {
    name: 'webURL',
    label: i18n('entities.company.fields.webURL'),
    schema: schemas.string(
      i18n('entities.company.fields.webURL'),
      {},
    ),
  },
  {
    name: 'emailDomain',
    label: i18n('entities.company.fields.emailDomain'),
    schema: schemas.string(
      i18n('entities.company.fields.emailDomain'),
      {},
    ),
  }
];