import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import votingEnumerators from 'src/modules/voting/votingEnumerators';
import dayjs from 'dayjs';

export default [
  {
    name: 'company',
    label: i18n('entities.voting.fields.company'),
    schema: schemas.relationToOne(
      i18n('entities.voting.fields.company'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'meetingType',
    label: i18n('entities.voting.fields.meetingType'),
    schema: schemas.enumerator(
      i18n('entities.voting.fields.meetingType'),
      {
        "required": true,
        "options": votingEnumerators.meetingType
      },
    ),
  },
  {
    name: 'votingDeadline',
    label: i18n('entities.voting.fields.votingDeadline'),
    schema: schemas.date(
      i18n('entities.voting.fields.votingDeadline'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'dateVoted',
    label: i18n('entities.voting.fields.dateVoted'),
    schema: schemas.date(
      i18n('entities.voting.fields.dateVoted'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'againstManagement',
    label: i18n('entities.voting.fields.againstManagement'),
    schema: schemas.boolean(
      i18n('entities.voting.fields.againstManagement'),
      {},
    ),
  },
  {
    name: 'attachments',
    label: i18n('entities.voting.fields.attachments'),
    schema: schemas.files(
      i18n('entities.voting.fields.attachments'),
      {},
    ),
  },
];