import importerActions from 'src/modules/shared/importer/importerActions';
import { listedCompanyImporterSelectors, unlistedCompanyImporterSelectors } from 'src/modules/company/importer/companyImporterSelectors';
import CompanyService from 'src/modules/company/companyService';
import unlistedFields from 'src/modules/company/importer/companyImporterFields';
import listedFields from 'src/modules/company/importer/companyImporterListedFields';
import { i18n } from 'src/i18n';
import { lookupHeadingTextForCode } from 'src/modules/shared/importer/importerUtils';

const createSampleListedValues = (fields: any[]) => {
  return [
    {
      [lookupHeadingTextForCode(fields, 'companyName')]: 'Microsoft Corporation',
      [lookupHeadingTextForCode(fields, 'isin')]: 'US5949181045',
    },
    {
      [lookupHeadingTextForCode(fields, 'companyName')]: 'Apple Inc.',
      [lookupHeadingTextForCode(fields, 'isin')]: 'US0378331005',
    },
  ];
}

const createSampleUnlistedValues = (fields: any[]) => {
  return [
    {
      [lookupHeadingTextForCode(fields, 'entityType')]: 'unlisted',
      [lookupHeadingTextForCode(fields, 'companyName')]: 'Acme Inc.',
      [lookupHeadingTextForCode(fields, 'industry')]: 'Technology',
      [lookupHeadingTextForCode(fields, 'sector')]: 'Software',
      [lookupHeadingTextForCode(fields, 'address')]: 'New York, NY',
      [lookupHeadingTextForCode(fields, 'country')]: 'US',
      [lookupHeadingTextForCode(fields, 'logo')]: 'https://picsum.photos/32/32',
      [lookupHeadingTextForCode(fields, 'webURL')]: 'https://acme.com',
      [lookupHeadingTextForCode(fields, 'emailDomain')]: 'acme.com',
    },
    {
      [lookupHeadingTextForCode(fields, 'entityType')]: 'unlisted',
      [lookupHeadingTextForCode(fields, 'companyName')]: 'Globex Corporation',
      [lookupHeadingTextForCode(fields, 'industry')]: 'Technology',
      [lookupHeadingTextForCode(fields, 'sector')]: 'Software',
      [lookupHeadingTextForCode(fields, 'address')]: 'Springfield, IL',
      [lookupHeadingTextForCode(fields, 'country')]: 'US',
      [lookupHeadingTextForCode(fields, 'logo')]: 'https://picsum.photos/32/32',
      [lookupHeadingTextForCode(fields, 'webURL')]: 'https://globex.com',
      [lookupHeadingTextForCode(fields, 'emailDomain')]: 'globex.com',
    },
    {
      [lookupHeadingTextForCode(fields, 'entityType')]: 'serviceProvider',
      [lookupHeadingTextForCode(fields, 'companyName')]: 'Impactive Tech Ltd.',
      [lookupHeadingTextForCode(fields, 'industry')]: 'Technology',
      [lookupHeadingTextForCode(fields, 'sector')]: 'Software',
      [lookupHeadingTextForCode(fields, 'address')]: 'London',
      [lookupHeadingTextForCode(fields, 'country')]: 'UK',
      [lookupHeadingTextForCode(fields, 'logo')]: 'https://www.theiaengine.com/wp-content/uploads/elementor/thumbs/ImpactiveBlueTransparent-q07o0leki3pqrvxgdknhayk6k2qz8au4d1surks4kk.png',
      [lookupHeadingTextForCode(fields, 'webURL')]: 'https://impactive.pro',
      [lookupHeadingTextForCode(fields, 'emailDomain')]: 'impactive.pro',
    },
    {
      [lookupHeadingTextForCode(fields, 'entityType')]: 'ngo',
      [lookupHeadingTextForCode(fields, 'companyName')]: 'UN PRI',
      [lookupHeadingTextForCode(fields, 'industry')]: 'Finance',
      [lookupHeadingTextForCode(fields, 'sector')]: 'Investment',
      [lookupHeadingTextForCode(fields, 'address')]: 'London',
      [lookupHeadingTextForCode(fields, 'country')]: 'UK',
      [lookupHeadingTextForCode(fields, 'logo')]: 'https://www.realisag.de/fileadmin/_processed_/8/b/csm_pri_8870d2222a.png',
      [lookupHeadingTextForCode(fields, 'webURL')]: 'https://unpri.org',
      [lookupHeadingTextForCode(fields, 'emailDomain')]: 'unpri.org',
    },
    {
      [lookupHeadingTextForCode(fields, 'entityType')]: 'body',
      [lookupHeadingTextForCode(fields, 'companyName')]: 'IIRC',
      [lookupHeadingTextForCode(fields, 'industry')]: 'Finance',
      [lookupHeadingTextForCode(fields, 'sector')]: 'Investment',
      [lookupHeadingTextForCode(fields, 'address')]: 'London',
      [lookupHeadingTextForCode(fields, 'country')]: 'UK',
      [lookupHeadingTextForCode(fields, 'logo')]: 'https://seeklogo.com/images/I/international-integrated-reporting-council-iirc-logo-CA65452918-seeklogo.com.png',
      [lookupHeadingTextForCode(fields, 'webURL')]: 'https://theiirc.org',
      [lookupHeadingTextForCode(fields, 'emailDomain')]: 'theiirc.org',
    },
    {
      [lookupHeadingTextForCode(fields, 'entityType')]: 'investor',
      [lookupHeadingTextForCode(fields, 'companyName')]: 'Artisan Partners LLP',
      [lookupHeadingTextForCode(fields, 'industry')]: 'Finance',
      [lookupHeadingTextForCode(fields, 'sector')]: 'Investment',
      [lookupHeadingTextForCode(fields, 'address')]: 'London',
      [lookupHeadingTextForCode(fields, 'country')]: 'UK',
      [lookupHeadingTextForCode(fields, 'logo')]: 'https://static.wixstatic.com/media/4ef974_081905a4255d45b1bdc974985cd059f4~mv2.png/v1/fill/w_192,h_192,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Logo_ARTWC.png',
      [lookupHeadingTextForCode(fields, 'webURL')]: 'https://www.artisanpartners.com',
      [lookupHeadingTextForCode(fields, 'emailDomain')]: 'artisanpartners.com',
    },
  ];
}

export const UnlistedCompanyImporterActions = importerActions(
  'UNLISTED_COMPANY_IMPORTER',
  unlistedCompanyImporterSelectors,
  CompanyService.import,
  unlistedFields,
  i18n('entities.company.importer.unlistedFileName'),
  20,
  'batch',
  createSampleUnlistedValues(unlistedFields),
);

export const ListedCompanyImporterActions = importerActions(
  'LISTED_COMPANY_IMPORTER',
  listedCompanyImporterSelectors,
  CompanyService.importListed,
  listedFields,
  i18n('entities.company.importer.listedFileName'),
  20,
  'batch',
  createSampleListedValues(listedFields),
);
