import * as ReactDOM  from 'react-dom/client';
import { i18n, init as i18nInit } from './i18n';
import { AuthToken } from './modules/auth/authToken';
import AuthService from './modules/auth/authService';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

(async function () {
  await i18nInit();
  const isSocialOnboardRequested = AuthService.isSocialOnboardRequested();
  AuthToken.applyFromLocationUrlIfExists();

  const TenantService = (await import('./modules/tenant/tenantService')).default;

  await TenantService.fetchAndApply();
  if (isSocialOnboardRequested) {
    await AuthService.socialOnboard();
  }

  const { configureStore } = await import('./modules/store');
  const store = configureStore();

  const SettingsService = (await import('./modules/settings/settingsService')).default;
  await SettingsService.applyThemeFromTenant();

  TimeAgo.addDefaultLocale(en);

  document.title = i18n('app.title');
  const App = require('./App').default;
  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
  root.render(<App store={store} />);
})();
