import list from 'src/modules/watchlist/list/watchlistListReducers';
import form from 'src/modules/watchlist/form/watchlistFormReducers';
import view from 'src/modules/watchlist/view/watchlistViewReducers';
import destroy from 'src/modules/watchlist/destroy/watchlistDestroyReducers';
import importerReducer from 'src/modules/watchlist/importer/watchlistImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
