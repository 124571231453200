import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircle, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import actions from 'src/modules/notification/list/notificationListActions';
import { useAppDispatch } from 'src/modules/hooks';
import UserAvatar from 'src/view/shared/UserAvatar';
import relativeTime from 'dayjs/plugin/relativeTime'
import { getHistory } from '../../modules/store';

dayjs.extend(relativeTime)

type NotificationType = {
  _id: string,
  event: string,
  entity: string,
  entityId: string,
  text: string,
  companyName: string,
  url: string,
  createdAt: string,
  opened: boolean,
  eventSourceUser: {
    _id: string,
    email: string,
    firstName: string,
    fullName: string,
    avatars: Array<{
      downloadUrl: string,
    }>
  },
  eventTargetUser?: {
    _id: string,
    email: string,
    firstName: string,
    fullName: string,
    avatars: Array<{
      downloadUrl: string,
    }>
  },
};

const NotificationContainer = styled.li`
  margin: 2px 0 4px 0;
  display: flex;
  width: 98%;
  min-height: 60px;
  border-radius: 4px;
  padding: 5px;

  :hover {
    cursor: pointer;
  }
`;

const NotificationIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  padding: 5px 10px 5px 5px;

  :hover {
    color: #0073ea;
  }
`;

const NotificationText = ({
  event,
  text,
  companyName,
  entity,
  eventSourceUser,
  eventTargetUser,
  currentUser,
}) => {
  const sourceUserName = eventSourceUser.firstName
    ? eventSourceUser.firstName
    : _.first(eventSourceUser.email.split(/[@._]/));

  const targetUserName = currentUser._id === eventTargetUser?._id
    ? 'you'
    : eventTargetUser?.firstName;

  const getOrEmpty = (a: string, pre: string = '',  post: string = '') => a ? `${pre} ${a} ${post}`  : '';

  const determinerText = (entity) => {
    switch (entity) {
      case 'interaction':
      case 'initiative':
      case 'objective':
        return 'an';
      default:
        return 'a';
    }
  };

  switch (event) {
    case 'updated':
    case 'created':
    case 'deleted':
    case 'assigned':
      return (<>
        <Link to={`/user/${eventSourceUser._id}`} onClick={e => e.stopPropagation()}>
          @{sourceUserName}
        </Link> {event} {getOrEmpty(targetUserName)} {determinerText(entity)} {entity} "{text}" {getOrEmpty(companyName, 'for')}
      </>);
  }
  return <></>;
};

const NotificationItem = ({
  _id,
  event,
  text,
  companyName,
  entity,
  url,
  createdAt,
  opened,
  eventSourceUser,
  eventTargetUser,
}: NotificationType) => {
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  return (
    <NotificationContainer
      style={{
        backgroundColor:
          opened
          ? hover ? '#EEEEEE' : '#FFFFFF'
          : hover ? '#DFDFDF' : '#DCEFFF'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div style={{
        width: '10%',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <UserAvatar user={eventSourceUser} noLabel={true} size="large" />
      </div>
      <div style={{ width: '75%' }} onClick={() => {
        dispatch(actions.doRead(_id));
        getHistory().push(url);
      }}>
        <NotificationText
          text={text}
          companyName={companyName}
          event={event}
          entity={entity}
          eventSourceUser={eventSourceUser}
          eventTargetUser={eventTargetUser}
          currentUser={currentUser}
        /><br/>
        {dayjs(createdAt).fromNow()}
      </div>
      <div style={{ width: '15%' }}>
        {hover && <span style={{
            paddingTop: '10px',
            paddingLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Tooltip key={_id} title={`Mark as ${opened ? 'unread' : 'read'}`}>
            { opened ?
              <NotificationIcon
                icon={faCircle}
                onClick={() => dispatch(actions.doUnRead(_id))}
              /> :
              <NotificationIcon
                icon={faCircleCheck}
                onClick={() => dispatch(actions.doRead(_id))}
              />
            }
          </Tooltip>
          <Tooltip title='Delete'>
            <NotificationIcon
              icon={faTrashCan}
              onClick={() => dispatch(actions.doDelete(_id))}
            />
          </Tooltip>
        </span>
        }
      </div>
    </NotificationContainer>
  );
};


export default NotificationItem;
